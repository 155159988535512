<template>
  <v-container id="admins-page" fluid tag="section">
    <v-row class="mx-0" align="center" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12">
        <base-material-card color="primary" class="px-5 py-3 w-100">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Manuals/Knowledge Articles
            </div>
            <div class="subtitle-1 font-weight-light">
              Upload Manuals/Knowledge Articles here
            </div>
          </template>
          <v-card-text class="w-100 px-3 py-5" ref="form">
            <v-row>
              <v-dialog v-model="dialog" max-width="600px">
                <v-card>
                  <v-card-title class="px-2 px-sm-6">
                    <v-row class="pb-3">
                      <v-col
                        cols="8"
                        lg="10"
                        class="d-flex flex-column justify-center align-start py-0"
                      >
                        <h2 class="headline text-left">Upload Manuals</h2>
                      </v-col>
                      <v-col
                        cols="4"
                        lg="2"
                        class="d-flex flex-column justify-center align-end py-0"
                      >
                        <v-btn
                          class="dialog-close-btn"
                          icon
                          color="grey lighten-1"
                          @click="dialog = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <!-- this is the file input to upload the venue proposal -->
                        <v-file-input
                          label="Manual File"
                          placeholder="Choose File*"
                          prepend-icon
                          append-icon="mdi-paperclip"
                          clear-icon="mdi-close"
                          v-model="file2"
                          @change="fileChanged"
                          ref="fileClearUpload"
                          truncate-length="14"
                          :error-messages="errorFile"
                          multiple
                          clearable
                          show-size
                          outlined
                          required
                          :key="fileUploadKey"
                          accept="application/pdf"
                          hide-details="auto"
                        ></v-file-input>
                        <!-- multiple -->
                        <v-row class="mx-3 mt-2 mb-6">
                          <span class="d-block text-left caption platinum--text text--darken-3 w-100">Accepted formats: PDF</span>
                          <span class="d-block text-left caption platinum--text text--darken-3 w-100">Maximum Size: 25 MB</span>
                        </v-row>
                      </v-col>

                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <!-- this is the file input to upload the venue proposal -->
                        <v-file-input
                          label="Thumbnail"
                          placeholder="Choose File*"
                          prepend-icon
                          append-icon="mdi-paperclip"
                          clear-icon="mdi-close"
                          v-model="file3"
                          @change="imageChanged"
                          ref="fileClearUpload"
                          truncate-length="14"
                          :error-messages="errorImage"
                          multiple
                          clearable
                          show-size
                          outlined
                          required
                          :key="imageUploadKey"
                          hide-details="auto"
                        ></v-file-input>
                        <!-- multiple -->
                        <v-row class="mx-3 mt-2 mb-6">
                  <span
                    class="d-block text-left caption platinum--text text--darken-3 w-100"
                  >Accepted formats: PNG/JPG</span
                  >
                          <span
                            class="d-block text-left caption platinum--text text--darken-3 w-100"
                          >Maximum Size: 10 MB</span
                          >
                        </v-row>
                      </v-col>

                    </v-row>
                  </v-card-text>
                  <v-card-actions class="px-6">
                    <v-row
                      class="
                d-flex
                flex-column-reverse flex-sm-row
                align-center
                justify-space-between
                mx-0
              "
                    >
                      <v-col
                        cols="12"
                        sm="3"
                        class="d-flex flex-column align-center align-sm-start px-0"
                      >
                        <v-btn
                          class="px-8"
                          color="secondary"
                          @click="dialog = false"
                          tile
                          outlined
                          depressed
                          bottom
                        >Cancel
                        </v-btn
                        >
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        class="d-flex flex-column align-center align-sm-end px-0"
                      >
                        <v-btn
                          type="submit"
                          class="px-12 mb-3 mx-auto"
                          color="secondary"
                          tile
                          depressed
                          bottom
                          @click="submitFn"
                          :loading="isLoader"
                        >Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-col cols="12" md="12" class="py-0" v-if="!isFileURL">
                <!-- this is the file input to upload the logo image -->
                <v-file-input
                  label="RFP File"
                  placeholder="Choose File*"
                  prepend-icon
                  append-icon="mdi-paperclip"
                  clear-icon="mdi-close"
                  v-model="file2"
                  @change="fileChanged"
                  ref="fileClearUpload"
                  truncate-length="14"
                  :error-messages="errorFile"
                  multiple
                  clearable
                  show-size
                  outlined
                  required
                  :key="fileUploadKey"
                  accept="application/pdf"
                  hide-details="auto"
                ></v-file-input>
                <!-- multiple -->
                <v-row class="mx-3 mt-2 mb-6">
                  <span
                    class="d-block text-left caption platinum--text text--darken-3 w-100"
                  >Accepted formats: PDF</span
                  >
                  <span
                    class="d-block text-left caption platinum--text text--darken-3 w-100"
                  >Maximum Size: 10 MB</span
                  >
                </v-row>
              </v-col>
              <v-col cols="12" class="py-0" v-else>
                <v-chip
                  style="height:120px"
                  class="floorplan-link ma-0 mb-2"
                  close
                  large
                  close-icon="mdi-close"
                  color="secondary"
                  label
                  text-color="white"
                  v-for="f in files"
                  :key="f"
                  @click:close="deleteManual(JSON.parse(f).pdf,JSON.parse(f).pdf.split('_')[JSON.parse(f).pdf.split('_').length - 1].replaceAll('%20', ' ').replaceAll('%', ' '))"
                >
                  <!-- <v-icon left>mdi-file</v-icon> -->
                  <img class="thumb" :src="JSON.parse(f).image" :alt="JSON.parse(f).pdf.split('.')[0]"
                       @click="openFloorPlanLink(JSON.parse(f).pdf)"/>
                  <v-tooltip left v-if="JSON.parse(f).image === 'https://www.conferli.com/img/placeholder.4fe9201a.svg'"
                             color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon dark v-bind="attrs"
                              v-on="on" style="cursor: pointer"
                              @click="openImageUploader(JSON.parse(f).pdf,JSON.parse(f).pdf.split('_')[JSON.parse(f).pdf.split('_').length - 1].replaceAll('%20', ' ').replaceAll('%', ' '))">
                        mdi-cloud-upload
                      </v-icon>
                    </template>
                    <span>Change thumbnail</span>
                  </v-tooltip>
                  <span style="margin-left: 3%;font-size: medium;font-weight: 600;cursor: pointer"
                        class="text-truncate" @click="openFloorPlanLink(JSON.parse(f).pdf)">{{
                      JSON.parse(f).pdf.split('_')[JSON.parse(f).pdf.split('_').length - 1].replaceAll('%20', ' ').replaceAll('%', ' ')
                        .replaceAll('+', ' ').replaceAll('+', ' ')
                    }}</span>
                </v-chip>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="!isFileURL">
              <v-col
                cols="6"
                class="d-flex flex-column align-center justify-space-between py-0"
              >
                <v-btn
                  type="submit"
                  class="px-12 mb-3 mx-auto"
                  color="secondary"
                  tile
                  depressed
                  bottom
                  @click="submitFn"
                  :loading="isLoader"
                >Submit
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex flex-column align-right justify-space-between py-0"
              >
                <v-btn
                  type="submit"
                  class="px-12 mb-3 mx-auto addbtn"
                  color="secondary"
                  tile
                  depressed
                  bottom
                  @click="uploadbtnfn"
                  :loading="isLoader"
                >Upload Article
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-row align="center" justify="center" v-else>
              <v-col
                cols="6"
                class="d-flex flex-column align-center justify-space-between py-0"
              >
                <v-btn
                  type="submit"
                  class="px-12 mb-3 mx-auto"
                  color="secondary"
                  tile
                  depressed
                  bottom
                  @click="submitFn"
                  :loading="isLoader"
                  >Edit</v-btn
                >
              </v-col>
            </v-row> -->
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <template>
      <div class="text-center">
        <v-dialog v-model="confirm" width="500">
          <v-card>
            <v-card-title class="text-h5 red lighten-2" style="text-align: center;justify-content: center;color: white;
font-weight: 300;align-content: center;align-items: center;margin-top: -10px;padding-bottom: 10px">
              Please Confirm
            </v-card-title>
            <v-card-text
              v-text="'Are you sure that you want to permanently delete '+fileDelName+'?' ">
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="color: white;font-weight: 300"
                color="primary"
                text
                @click="confirm = false"
              >
                Cancel
              </v-btn>
              <v-btn
                style="color: white;font-weight: 300"
                color="error"
                text
                @click="deleteConfirmed"
              >
                Delete Manual
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template>
      <div class="text-center">
        <v-dialog v-model="noImageConfirm" width="500">
          <v-card>
            <v-card-title class="text-h5 primary lighten-2" style="text-align: center;justify-content: center;color: white;
font-weight: 300;align-content: center;align-items: center;margin-top: -10px;padding-bottom: 10px">
              Upload Without Thumbnail?
            </v-card-title>
            <v-card-text
              v-text="'You did not select document thumbnail. Do you wish to upload the manual without thumbnail?'">
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="color: white;font-weight: 300"
                color="primary"
                text
                @click="noImageConfirm = false;dialog = true;"
              >
                Select thumbnail
              </v-btn>
              <v-btn
                style="color: white;font-weight: 300"
                color="success"
                text
                @click="uploadManualOnly"
              >
                Upload Manual
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="imageUploader"
          persistent
          max-width="450"
        >
          <v-card>
            <v-card-title class="text-h5">
              Update thumbnail
            </v-card-title>
            <v-card-text>
              <v-file-input
                :label="'Choose a thumbnail for '+imageForName"
                placeholder="Choose An Image File"
                prepend-icon
                append-icon="mdi-paperclip"
                clear-icon="mdi-close"
                v-model="file3"
                @change="imageChanged"
                ref="fileClearUpload"
                truncate-length="14"
                :error-messages="errorImage"
                multiple
                clearable
                show-size
                outlined
                required
                :key="imageUploadKey"
                hide-details="auto"
              ></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="imageUploader = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="uploadImageOnly"
                :loading="isLoader"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="notification"
          persistent
          max-width="450"
        >
          <v-card>
            <v-card-title class="text-h5">
              Notify Organizers
            </v-card-title>
            <v-card-text>
              <span>Article Name</span>
              <v-text-field v-model="lastUp" style="width: 100%;height: 20px"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="notification = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="sendNotification"
                :loading="isLoader"
              >
                Send Notification
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
// /* eslint-disable */
// import CheckEmail from "../../components/util/CheckEmail";
import hybridAPIs from "../../../services/hybrid.service";
import Upload from "../../../services/imageUpload.service";

export default {
  name: "Register",
  data() {
    return {
      userName: null,
      file3: null,
      file2: null,
      file: false,
      errorFile: null,
      errorImage: null,
      fileUploadKey: Number(new Date()),
      imageUploadKey: Number(new Date()),
      adminId: null,
      isFileURL: false,
      fileURL: null,
      isLoader: false,
      dialog: false,
      numofproposals: 0,
      files: [],
      confirm: false,
      noImageConfirm: false,
      imageUploader: false,
      fileDelName: '',
      fileDelLink: '',
      imageForLink: '',
      imageForName: '',
      notification: false,
      lastUp: ''
    };
  },
  mounted() {
    this.adminId = JSON.parse(localStorage.getItem("admin")).admin._id;
    // console.log("ad", this.adminId);
    if (!this.isFileURL) {
      this.fileGetFn();
    }
  },
  // computed: {
  //   isFile(){
  //     if(this.file2){
  //       return true
  //     }else{
  //       return false
  //     }
  //   }
  // },
  methods: {
    sendNotification() {
      if (!this.lastUp || this.lastUp.length < 1) {
        this.$store.dispatch("notify/setSnackbarMessage", {
          snackBarText: "Document name is not valid!",
          snackBarColor: "orange",
        });
        return
      }
      const data = {
        name: this.lastUp
      }
      hybridAPIs.triggerNotification(data);
      this.notification = false;
    },
    openImageUploader(link, name) {
      this.imageUploader = true;
      this.imageForLink = link;
      this.imageForName = name;
    },
    async uploadImageOnly() {
      if (this.file3) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
        let payload = {
          Path: `${Number(new Date())}`,
          Base64: await toBase64(this.file3[0]),
        };
        this.isLoader = true;
        await Upload.imageUpload(payload).then((res) => {
          let payload = {
            link: this.imageForLink,
            image: res.data.content
          }
          hybridAPIs.updateManual(payload).then((res) => {
            this.files = res.data.content.manualUploadURL;
            this.numofproposals = res.data.content.manualUploadURL.length;
            this.fileDelName = '';
            this.fileDelLink = '';
            this.imageForLink = '';
            this.imageForName = '';
            this.file3 = null;
            this.imageUploader = false;
            let payload = {
              snackBarText: 'Document Updated!',
              snackBarColor: "success",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload);
          });
          this.isLoader = false;
        }, reason => {
          this.isLoader = false;
          this.imageUploader = false;
          let payload = {
            snackBarText: 'Update Failed!',
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload);
          console.log(reason)
        });
      } else {
        this.isLoader = false;
        let payload = {
          snackBarText: 'Please select an image first',
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
      }
    },
    deleteManual(link, name) {
      this.fileDelName = name;
      this.fileDelLink = link;
      this.confirm = true;
    },
    deleteConfirmed() {
      this.confirm = false;
      let payload = {
        link: this.fileDelLink
      }
      hybridAPIs.deleteManual(payload).then((res) => {
        this.files = res.data.content.manualUploadURL;
        this.numofproposals = res.data.content.manualUploadURL.length;
        let payload = {
          snackBarText: this.fileDelName + ' removed!',
          snackBarColor: "success",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.fileDelName = '';
        this.fileDelLink = '';
      });
    },
    uploadbtnfn() {
      if (this.numofproposals <= 19) {
        this.dialog = true;
      } else {
        let payload = {
          snackBarText: "You've exceeded the upload limit",
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
      }
    },
    async submitFn() {
      if (this.file2 && this.file3) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
        let payload = {
          Path: `${Number(new Date())}`,
          Base64: await toBase64(this.file3[0]),
        };
        this.isLoader = true;
        await Upload.imageUpload(payload).then((res) => {
          console.log(res.data.content)
          let payload = {
            id: this.adminId,
            isFile: this.file2 ? true : false,
            image: res.data.content
          };
          var formData = new FormData();
          if (this.file2) {
            for (let k = 0; k < this.file2.length; k++) {
              formData.append(`file_${k}`, this.file2[k]);
            }
          }
          formData.set("detail", JSON.stringify(payload));
          // console.log("form data", formData.getAll("detail"));
          this.isLoader = true;
          hybridAPIs
            .uploadFile(formData)
            .then((res) => {
              this.isLoader = false;
              // console.log(res);
              if (res.data.message == "success") {
                this.dialog = false;
                let payload = {
                  snackBarText: "Your file is successfully uploaded",
                  snackBarColor: "green",
                };
                this.$store.dispatch("notify/setSnackbarMessage", payload);
                this.isFileURL = true;
                this.fileURL = res.data.content.manualUploadURL[0];
                this.lastUp = this.file2[0].name.split('.')[0];
                this.file2 = null;
                this.file3 = null;
                this.notification = true;
              }
              this.fileGetFn();
            })
            .catch(() => {
              this.isLoader = false;
              // console.log(err);
            });
        }, reason => {
          let payload = {
            snackBarText: "Something went wrong!",
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload);
          console.log(reason);
        });
      } else if (this.file2) {
        this.noImageConfirm = true;
      } else {
        let payload = {
          snackBarText: "Please choose your file and thumbnail",
          snackBarColor: "orange",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
      }
    },
    uploadManualOnly() {
      this.noImageConfirm = false;
      let payload = {
        id: this.adminId,
        isFile: this.file2 ? true : false,
        image: 'https://www.conferli.com/img/placeholder.4fe9201a.svg'
      };
      var formData = new FormData();
      if (this.file2) {
        for (let k = 0; k < this.file2.length; k++) {
          formData.append(`file_${k}`, this.file2[k]);
        }
      }
      formData.set("detail", JSON.stringify(payload));
      // console.log("form data", formData.getAll("detail"));
      this.isLoader = true;
      hybridAPIs
        .uploadFile(formData)
        .then((res) => {
          this.isLoader = false;
          // console.log(res);
          if (res.data.message == "success") {
            this.dialog = false;
            let payload = {
              snackBarText: "Your file is successfully uploaded",
              snackBarColor: "green",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload);
            this.isFileURL = true;
            this.fileURL = res.data.content.manualUploadURL[0];
            this.lastUp = this.file2[0].name.split('.')[0];
            this.file2 = null;
            this.file3 = null;
            this.notification = true;
          }
          this.fileGetFn();
        }).catch(() => {
        this.isLoader = false;
        // console.log(err);
      });
    },
    fileChanged(file) {
      let size = 0;
      if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          const tmpfile = file[i].size / 1024 / 1024;
          size = size + tmpfile;
        }
      }
      // let size = file ? file.size / 1024 / 1024 : 0;
      if (size >= 25) {
        let payload = {
          snackBarText: "Your file is too big! Please select a file under 25MB",
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.file2 = null;

        this.fileUploadKey = Number(new Date());
      } else {
        if (file) {
          this.file2 = file;
          this.errorFile = null;
        } else {
          this.file2 = false;
        }
      }
    },
    imageChanged(file) {
      let size = 0;
      if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          const tmpfile = file[i].size / 1024 / 1024;
          size = size + tmpfile;
        }
      }
      // let size = file ? file.size / 1024 / 1024 : 0;
      if (size >= 10) {
        let payload = {
          snackBarText: "Your image is too big! Please select a file under 10MB",
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.file3 = null;

        this.imageUploadKey = Number(new Date());
      } else {
        if (file) {
          this.file3 = file;
          this.errorImage = null;
        } else {
          this.file3 = false;
        }
      }
    },
    fileGetFn() {
      this.isLoader = true;
      hybridAPIs
        .getManualFile()
        .then((res) => {
          console.log(res.data);
          this.isLoader = false;
          if (res.data.message == "success") {
            this.isFileURL = true;
            this.fileURL = res.data.content.manualUploadURL[0];
            this.files = res.data.content.manualUploadURL;
            this.numofproposals = res.data.content.manualUploadURL.length;
          }
        })
        .catch(() => {
          this.isLoader = false;
          // console.log(err);
        });
    },
    openFloorPlanLink(link) {
      // console.log("download", this.fileURL);
      window.open(link, "_blank");
    },
  },
};
</script>
<style>
#register {
  background-image: linear-gradient(
    to left,
    rgba(53, 167, 255, 0.3),
    rgba(53, 167, 255, 0.3)
  ),
  url("https://source.unsplash.com/w1g2o4J_4Dg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.thumb {
  cursor: pointer;
  width: 200px;
  height: 100px;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-right: 1%;
  border-radius: 5px;
  object-fit: cover;
}

.register-form-card {
  position: relative;
  width: 600px;
}

.register-form-row {
  /* position: absolute; */
  top: 0;
  min-width: 100vw;
  /* the 64px is to offset the top app bar */
  height: calc(100vh - 64px);
  min-height: 300px;
}

.addbtn {
  left: 40%;
}

.floorplan-link {
  width: 100%;
}

.floorplan-link .v-chip__content {
  width: 100%;
}

.floorplan-link .v-chip__content button.v-icon.mdi-close {
  margin-left: auto;
}

.register-image-credits {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
}

.register-image-credits a {
  color: white;
}

.register-image-credits a:hover {
  color: gray;
}

/* Medium devices (large tablet to laptop, less than 960px) */
@media (max-width: 960px) {
  .addbtn {
    left: 30%;
  }

  .register-form-card {
    width: 80vw;
    min-width: 200px;
  }

  /* the 56px is to offset the top app bar on smaller devices */
  .register-cover-image {
    height: calc(100vh - 56px);
  }

  /* the 56px is to offset the top app bar on smaller devices */
  .register-form-row {
    height: calc(100vh - 56px);
  }
}
</style>
